import React, { useRef, useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  Avatar,
  Badge,
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popover,
  SvgIcon,
  Tooltip,
  Typography,
  makeStyles
} from '@material-ui/core';
import {
  Package as PackageIcon,
  MessageCircle as MessageIcon,
  Truck as TruckIcon,
  MessageCircle,
  ExternalLink
} from 'react-feather';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

const iconsMap = {
  order_placed: PackageIcon,
  new_message: MessageIcon,
  item_shipped: TruckIcon
};

const useStyles = makeStyles(theme => ({
  popover: {
    width: 320
  },
  icon: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText
  }
}));

function InquiryMessage() {
  const classes = useStyles();
  const { user } = useSelector(state => state.account);
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const [list, setList] = useState([]);
  const history = useHistory();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getAllInquiryList = React.useCallback(() => {
    let userId =
      user?.roles[0]?.name === 'user' ? user?.id : user?.roles[0]?.id;

    axios
      .get('/api/ebpls/inquiry/get-list-of-inquiries', {
        params: {
          receiver_id: userId
        }
      })
      .then(({ data }) => {
        setList(data);
      });
  }, [user]);

  const handleMarkAllInquiryAsRead = () => {
    let userId =
      user?.roles[0]?.name === 'user' ? user?.id : user?.roles[0]?.id;
    axios
      .post('/api/ebpls/inquiry/set-all-inquiries-read', {
        receiver_id: userId
      })
      .then(({ data }) => {
        if (data.message === 'success') {
          getAllInquiryList();
        }
      });
  };

  useEffect(() => {
    getAllInquiryList();
  }, [getAllInquiryList]);

  let url =
    user?.roles[0]?.name === 'user'
      ? '/app/inquiry?new=yes'
      : user?.roles[0]?.name.includes('barangay')
      ? '/barangay/inquiry'
      : `/${user?.roles[0]?.name}/inquiry`;

  return (
    <>
      <Tooltip title="Inquiries">
        <IconButton color="inherit" ref={ref} onClick={handleOpen}>
          <Badge color={'error'} badgeContent={list.length}>
            <MessageCircle />
          </Badge>
        </IconButton>
      </Tooltip>

      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        classes={{ paper: classes.popover }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >
        <Box
          p={2}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <Typography variant="h5" color="textPrimary">
            Inquiries
          </Typography>

          <Tooltip title={'View inquiries'}>
            <IconButton color={'primary'} onClick={() => history.push(url)}>
              <ExternalLink />
            </IconButton>
          </Tooltip>
        </Box>

        <Box
          sx={{
            maxHeight: '350px',
            overflow: 'auto'
          }}
        >
          {list.length === 0 ? (
            <Box p={2}>
              <Typography variant="h6" color="textPrimary">
                There are no inquiry
              </Typography>
            </Box>
          ) : (
            <>
              <List className={classes.list} disablePadding>
                {list.map(inquiry => {
                  const Icon = iconsMap['new_message'];
                  const type =
                    user?.roles?.[0]?.name === 'user'
                      ? 'app'
                      : user?.roles?.[0]?.name;
                  return (
                    <ListItem
                      className={classes.listItem}
                      component={RouterLink}
                      divider
                      key={inquiry.id}
                      to={`/${type}/inquiry`}
                    >
                      <ListItemAvatar>
                        <Avatar className={classes.icon}>
                          <SvgIcon fontSize="small">
                            <Icon />
                          </SvgIcon>
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={inquiry.sender_type}
                        secondary={inquiry.message}
                        primaryTypographyProps={{
                          noWrap: true,
                          variant: 'subtitle2',
                          color: 'textPrimary',
                          style: {
                            textTransform: 'uppercase'
                          }
                        }}
                        secondaryTypographyProps={{
                          noWrap: true
                        }}
                      />
                    </ListItem>
                  );
                })}
              </List>
            </>
          )}
        </Box>

        <Box p={1} display="flex" justifyContent="center">
          <Button onClick={handleMarkAllInquiryAsRead}>Mark all as read</Button>
        </Box>
      </Popover>
    </>
  );
}

export default InquiryMessage;
