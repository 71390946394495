import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, makeStyles } from '@material-ui/core';
import NavBar from './NavBar';
import TopBar from './TopBar';
import { Alert } from '@material-ui/lab';
import { Info } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 256
    }
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  }
}));

function DashboardLayout({ children }) {
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  const [showInfo, setShowInfo] = useState(
    localStorage.getItem('show_info') || 'yes'
  );

  return (
    <div className={classes.root}>
      {showInfo === 'yes' && (
        <Box
          sx={{
            position: 'fixed',
            zIndex: 9999,
            width: 'calc(100vw)'
          }}
        >
          <Alert
            onClose={() => {
              localStorage.setItem('show_info', 'no');
              setShowInfo('no');
            }}
            severity="warning"
            icon={<Info fontSize="inherit" />}
          >
            This is the Official website of the eBPLS LGU Guiguinto. By using
            this website, you agree to the terms of the eBPLS Guiguinto Privacy
            Notice.
          </Alert>
        </Box>
      )}

      <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
      <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>{children}</div>
        </div>
      </div>
    </div>
  );
}

DashboardLayout.propTypes = {
  children: PropTypes.any
};

export default DashboardLayout;
