import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import Hero from './Hero';
const useStyles = makeStyles(() => ({
  root: {}
}));

function HomeView() {
  const classes = useStyles();

  return (
    <Page className={classes.root} title="Home">
      <Box>
        <Hero />
      </Box>
      {/* <Features />
      <Testimonials />
      <CTA />
      <FAQS /> */}
    </Page>
  );
}

export default HomeView;
