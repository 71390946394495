import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles,
  SvgIcon
} from '@material-ui/core';
import { Menu as MenuIcon } from 'react-feather';
import Logo from 'src/components/Logo';
import { THEMES } from 'src/constants';
import Account from './Account';
import Contacts from './Contacts';
import Notifications from './Notifications';
import Search from './Search';
import Settings from './Settings';
import { useDispatch, useSelector } from 'react-redux';
import { useContext } from 'react';
import { SocketContext } from 'src/context/SocketContext';
import { useSnackbar } from 'notistack';
import { getNotifications } from 'src/actions/notificationsActions';
import InquiryMessage from './InquiryMessage';

const useStyles = makeStyles(theme => ({
  root: {
    zIndex: theme.zIndex.drawer + 100,
    ...(theme.name === THEMES.LIGHT
      ? {
          boxShadow: 'none',
          backgroundColor: theme.palette.primary.main
        }
      : {}),
    ...(theme.name === THEMES.ONE_DARK
      ? {
          backgroundColor: theme.palette.background.default
        }
      : {})
  },
  toolbar: {
    minHeight: 64
  }
}));

function TopBar({ className, onMobileNavOpen, ...rest }) {
  const classes = useStyles();
  const { user } = useSelector(state => state.account);
  const { socket } = useContext(SocketContext);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (user?.id) {
      socket.emit('join', {
        userId: user.id
      });

      socket.on('received-notification', data => {
        dispatch(getNotifications());

        enqueueSnackbar(
          <div>
            Notifications <br /> {data.message}
          </div>,
          {
            variant: 'success'
          }
        );
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <>
      <AppBar className={clsx(classes.root, className)} {...rest}>
        <Toolbar className={classes.toolbar}>
          <Hidden lgUp>
            <IconButton
              className={classes.menuButton}
              color="inherit"
              onClick={onMobileNavOpen}
            >
              <SvgIcon fontSize="small">
                <MenuIcon />
              </SvgIcon>
            </IconButton>
          </Hidden>
          <Hidden mdDown>
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </Hidden>
          <Box ml={2} flexGrow={1} />

          <Hidden xsDown xsUp>
            <Search />
            <Contacts />
          </Hidden>

          <InquiryMessage />

          <Notifications />

          <Settings />
          <Box ml={2} id="stepTen">
            <Account />
          </Box>
        </Toolbar>
      </AppBar>
    </>
  );
}

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

export default TopBar;
