import axios from 'axios';

export const GET_NOTIFICATIONS = '@notifications/get-notifications';

export function getNotifications() {
  // const request = axios.get('/api/notifications');
  const request = axios.get('/api/ebpls/notifications/get-users-notification');

  return dispatch => {
    request.then(response => {
      dispatch({
        type: GET_NOTIFICATIONS,
        payload: response.data
      });
    });
  };
}
