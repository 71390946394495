/* eslint-disable import/prefer-default-export */
export const THEMES = {
  LIGHT: 'LIGHT',
  ONE_DARK: 'ONE_DARK',
  UNICORN: 'UNICORN'
};

export const socketBaseURL =
  process.env.NODE_ENV === 'production'
    ? 'https://bnrs-api.ebpls-guiguinto.com'
    : 'http://localhost:8089';
