import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

function ModeratorGuard({ children }) {
  const account = useSelector(state => state.account);

  if (!account.user) {
    return <Redirect to="/login" />;
  }

  if (account.user.roles.length > 0 && account.user.roles[0].name === 'user') {
    return <Redirect to="/app/dashboard" />;
  }

  if (account.user.roles.length > 0 && account.user.roles[0].name === 'admin') {
    return <Redirect to="/administrator/dashboard" />;
  }

  if (account.user.roles.length > 0 && account.user.roles[0].name === 'fire') {
    return <Redirect to="/fire/dashboard" />;
  }

  if (
    account.user.roles.length > 0 &&
    account.user.roles[0].name === 'engineering'
  ) {
    return <Redirect to="/engineering/dashboard" />;
  }

  if (account.user.roles.length > 0 && account.user.roles[0].name === 'mpdo') {
    return <Redirect to="/mpdo/dashboard" />;
  }

  if (account.user.roles.length > 0 && account.user.roles[0].name === 'rhu') {
    return <Redirect to="/rhu/dashboard" />;
  }

  if (account.user.roles.length > 0 && account.user.roles[0].name === 'menro') {
    return <Redirect to="/menro/dashboard" />;
  }

  if (
    account.user.roles.length > 0 &&
    account.user.roles[0].name === 'assessor'
  ) {
    return <Redirect to="/assessor/dashboard" />;
  }

  if (account.user.roles.length > 0 && account.user.roles[0].name === 'peso') {
    return <Redirect to="/peso/dashboard" />;
  }

  if (
    account.user.roles.length > 0 &&
    account.user.roles[0].name.includes('barangay')
  ) {
    return <Redirect to="/barangay/dashboard" />;
  }

  if (account.user.roles.length > 0 && account.user.roles[0].name === 'bplo') {
    return <Redirect to="/bplo/dashboard" />;
  }

  if (account.user.roles.length > 0 && account.user.roles[0].name === 'mao') {
    return <Redirect to="/mao/dashboard" />;
  }

  if (
    account.user.roles.length > 0 &&
    account.user.roles[0].name === 'affiliate'
  ) {
    return <Redirect to="/affiliate/dashboard" />;
  }

  return children;
}

ModeratorGuard.propTypes = {
  children: PropTypes.any
};

export default ModeratorGuard;
